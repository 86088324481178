import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { listPages } from "../../graphql/queries";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Blog.scss"; // Your blog styling

const Blog = () => {
  const client = generateClient();
  const [pages, setPages] = useState([]);

  useEffect(() => {
    fetchPages(); // Fetch pages when component mounts
  }, []);

  // Fetch pages from the API and sort by createdAt
  const fetchPages = async () => {
    try {
      const apiData = await client.graphql({ query: listPages });
      const sortedPages = apiData.data.listPages.items.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt) // Sort in descending order
      );
      setPages(sortedPages); // Set the sorted pages in state
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  return (
    <div className="blog">
      <div className="container">
        <h1>Latest Blog Posts</h1>
        {pages.length === 0 ? (
          <p>No blog posts available.</p>
        ) : (
          <div className="blog-list">
            {pages.map((page) => (
              <div key={page.id} className="blog-item">
                {/* Check if the page URL already includes '/blog/' and remove it */}
                <Link to={`/blog/${page.url.replace("/blog/", "")}`}>
                  <h2>{page.title}</h2>
                </Link>
                <p>
                  Published on: {new Date(page.createdAt).toLocaleDateString()}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
