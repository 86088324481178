import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { listPages } from "../graphql/queries";
import { createPage } from "../graphql/mutations";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import "./Admin.scss";

const AddPage = () => {
  const client = generateClient();
  const [newPage, setNewPage] = useState({
    title: "",
    url: "",
    content: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPages();
  }, []);

  const fetchPages = async () => {
    try {
      const apiData = await client.graphql({ query: listPages });
      setPages(apiData.data.listPages.items);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  const slugify = (title) => {
    return `/blog/${title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .trim()
      .replace(/\s+/g, "-")}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      const formattedUrl = slugify(value);
      setNewPage({ ...newPage, title: value, url: formattedUrl });
    } else {
      setNewPage({ ...newPage, [name]: value });
    }
  };

  const handleContentChange = (content) => {
    // Automatically add protocol to links that don't have it
    const autoLink = content.replace(
      /href="(www\.[^\s]+)"/g,
      'href="https://$1" target="_blank" rel="noopener noreferrer"'
    );
    setNewPage({ ...newPage, content: autoLink });
  };

  const isDuplicateUrl = (url) => {
    return pages.some((page) => page.url === url);
  };

  const handleCreatePage = async () => {
    if (!newPage.title || !newPage.content) {
      setError("Please fill out all fields.");
      return;
    }

    if (isDuplicateUrl(newPage.url)) {
      setError(
        "A page with this URL already exists. Please choose a different title."
      );
      return;
    }

    const pageData = { ...newPage };

    try {
      setIsSubmitting(true);
      await client.graphql({
        query: createPage,
        variables: { input: pageData },
      });
      setSuccess("Page created successfully.");
      setError("");

      setTimeout(() => {
        navigate("/my-admin");
      }, 2000);
    } catch (error) {
      console.error("Error creating page:", error);
      setError("Error creating page. Please try again.");
      setIsSubmitting(false);
    }
  };

  return (
    <div className="admin-page">
      <h1>Create New Page</h1>
      <div className="create-page">
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        <input
          type="text"
          name="title"
          placeholder="Page Title"
          value={newPage.title}
          onChange={handleChange}
        />
        <input
          type="text"
          name="url"
          placeholder="Page URL"
          value={newPage.url}
          readOnly
        />

        {/* Replace the regular textarea with the ReactQuill editor */}
        <ReactQuill
          value={newPage.content}
          onChange={handleContentChange}
          placeholder="Page Content"
          modules={{
            toolbar: [
              [{ header: "2" }, { header: "3" }, { font: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline"],
              ["link", "image", "video"],
              [{ align: [] }],
              ["clean"],
            ],
          }}
          formats={[
            "header",
            "font",
            "list",
            "bullet",
            "bold",
            "italic",
            "underline",
            "link",
            "image",
            "video",
            "align",
          ]}
        />

        <button onClick={handleCreatePage} disabled={isSubmitting}>
          {isSubmitting ? "Creating..." : "Create Page"}
        </button>
      </div>
    </div>
  );
};

export default AddPage;
