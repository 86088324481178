import "./Contact.scss";
// import ContactForm from "../../Components/Forms/Contact/Contact";
// import BrevoContact from "../../Components/Forms/Brevo/BrevoContact";

const Contact = () => {
  return (
    <div className="contact">
      {/* <div className="container">
        <h1>Contact Us</h1> */}
      {/* <ContactForm /> */}
      {/* <BrevoContact /> */}
      <div className="iframe-container">
        <iframe
          title="contain-form"
          width="375"
          height="1250"
          src="https://ee04d834.sibforms.com/serve/MUIFAPwxoZJfvgrAj4xh6qJInZZULh2Cheof7WYstVR01aL9N-H09TghIH1hORbs0DKjDD7JGyM1P9XYeWcrOOw1ZaOwEnIt9mQaIbNy0QkQsSEruiDzw9uThXZ6OP_AHeBFucMfJVCQ9sTUA913D4Lm0CX85YAo_HeLV8rJKvzEyzfz4I8F0TFokg3iCsTWEeHfDiQwRwiSMPi6"
          frameborder="0"
          scrolling="auto"
          allowfullscreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        ></iframe>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Contact;
