import React, { useEffect, useRef } from "react";
import "./Home.scss";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
// import ContactForm from "../../Components/Forms/Contact/Contact";

const Home = () => {
  const waveIconRef = useRef(null); // Ref for the wave icon
  const padlockRef = useRef(null); // Ref for the padlock

  useEffect(() => {
    const waveIcon = waveIconRef.current?.querySelectorAll(".arc");
    const padlockShackle =
      padlockRef.current?.querySelectorAll(".padlock-shackle");

    // Function to handle animations for elements when they come into view
    const handleAnimation = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // For the wave icon
          if (entry.target.classList.contains("arc")) {
            const arc = entry.target;
            arc.classList.add("animate-wave");
          }

          // For the padlock
          if (entry.target.classList.contains("padlock-shackle")) {
            const shackle = entry.target;
            shackle.classList.add("animate-shackle");
          }
        } else {
          // Reset the wave animation when out of view
          if (entry.target.classList.contains("arc")) {
            const arc = entry.target;
            arc.classList.remove("animate-wave");
            void arc.offsetWidth; // Force reflow to reset animation
          }

          // Reset the padlock animation when out of view
          if (entry.target.classList.contains("padlock-shackle")) {
            const shackle = entry.target;
            shackle.classList.remove("animate-shackle");
            void shackle.offsetWidth; // Force reflow to reset animation
          }
        }
      });
    };

    // Create IntersectionObserver instances for both wave and padlock
    const observer = new IntersectionObserver(handleAnimation, {
      threshold: 0.1, // Trigger when 10% of the element is visible
    });

    // Observe wave icon arcs
    waveIcon?.forEach((arc) => {
      if (arc instanceof Element) {
        observer.observe(arc);
      }
    });

    // Observe padlock shackles
    padlockShackle?.forEach((shackle) => {
      if (shackle instanceof Element) {
        observer.observe(shackle);
      }
    });

    // Cleanup observer on component unmount
    return () => {
      waveIcon?.forEach((arc) => {
        if (arc instanceof Element) {
          observer.unobserve(arc);
        }
      });
      padlockShackle?.forEach((shackle) => {
        if (shackle instanceof Element) {
          observer.unobserve(shackle);
        }
      });
    };
  }, []);

  return (
    <div className="home">
      <div className="container section section1">
        <h1>
          Shake up your brand and give it that '<span className="oo">OO</span>
          <span className="after-oo"> ' </span>
          factor.
        </h1>
        <p className="text-under-heading">
          Boost your brand’s potential with the best progressive web application
          technology and design.
        </p>
      </div>

      <div className="container section section2">
        <div className="section-block">
          <h2>
            Increase your website's Google search rankings with unparalleled
            speed and performance.
          </h2>
        </div>
        <div className="section-block">
          <ProgressBar />
        </div>
      </div>

      <div className="container section section3">
        <div className="section-block">
          <div ref={waveIconRef} className="wave-icon">
            <div className="arc arc1"></div>
            <div className="arc arc2"></div>
            <div className="arc arc3"></div>
            <div className="line"></div>
          </div>
        </div>
        <div className="section-block">
          <h2>
            Your web app will be downloadable as an application for your phone,
            tablet or desktop.
          </h2>
        </div>
      </div>

      <div className="container section section4">
        <div className="section-block">
          <h2>
            Custom and user-friendly content management system, SSL
            certification and fully managed hosting.
          </h2>
        </div>
        <div className="section-block padlock-block" ref={padlockRef}>
          <div className="padlock">
            <div className="padlock-body"></div>
            <div className="padlock-shackle"></div>
            <div className="padlock-keyhole"></div>
            <div className="padlock-shackle cover"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
