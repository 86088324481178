import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { getPage } from "../graphql/queries"; // Import getPage from queries
import { updatePage } from "../graphql/mutations"; // Import updatePage from mutations
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill"; // Import ReactQuill for WYSIWYG editor
import "react-quill/dist/quill.snow.css"; // Quill CSS
import "./Admin.scss";

const EditPage = () => {
  const client = generateClient();
  const { id } = useParams(); // Get the page ID from URL parameters
  const navigate = useNavigate();

  const [pageData, setPageData] = useState({
    id: "",
    title: "",
    url: "",
    content: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // New success state

  // Function to convert the title into a slug
  const slugify = (title) => {
    return `/blog/${title
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "") // Remove special characters
      .trim()
      .replace(/\s+/g, "-")}`; // Replace spaces with hyphens
  };

  // Fetch the page data when the component mounts
  useEffect(() => {
    const fetchPage = async () => {
      try {
        const result = await client.graphql({
          query: getPage,
          variables: { id },
        });
        const page = result.data.getPage;
        if (page) {
          setPageData(page); // Set the fetched page data to state
        } else {
          setError("Page not found.");
        }
      } catch (err) {
        console.error("Error fetching page data:", err);
        setError("Error fetching page data. Please try again.");
      }
    };

    fetchPage();
  }, [id]);

  // Handle input changes for title and other fields
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Automatically update the URL as the title changes
    if (name === "title") {
      const formattedUrl = slugify(value); // Convert the title to a URL format
      setPageData({ ...pageData, title: value, url: formattedUrl });
    } else {
      setPageData({ ...pageData, [name]: value });
    }
  };

  // Handle WYSIWYG content changes
  const handleContentChange = (content) => {
    setPageData({ ...pageData, content });
  };

  // Handle page update
  const handleUpdatePage = async () => {
    if (!pageData.title || !pageData.content) {
      setError("Please fill out all fields.");
      setSuccess(false); // Clear success message if any
      return;
    }

    const updatedPageData = {
      id: pageData.id,
      title: pageData.title,
      url: pageData.url,
      content: pageData.content,
    };

    try {
      await client.graphql({
        query: updatePage,
        variables: { input: updatedPageData },
      });
      console.log("Page updated successfully.");
      setError(""); // Clear any errors
      setSuccess(true); // Show success message

      // Navigate back to the admin page after a short delay
      setTimeout(() => {
        navigate("/my-admin");
      }, 2000); // Redirect after 2 seconds (you can adjust the delay if needed)
    } catch (error) {
      console.error("Error updating page:", error);
      setError("Error updating page. Please try again.");
      setSuccess(false); // Clear success message in case of error
    }
  };

  return (
    <div className="admin-page">
      <h1>Edit Page</h1>
      <div className="edit-page">
        {error && <p className="error-message">{error}</p>}
        {success && (
          <p className="success-message">Page updated successfully!</p>
        )}

        <input
          type="text"
          name="title"
          placeholder="Page Title"
          value={pageData.title}
          onChange={handleChange} // Automatically slugify and update URL when title changes
        />
        <input
          type="text"
          name="url"
          placeholder="Page URL"
          value={pageData.url}
          readOnly
        />

        {/* WYSIWYG Editor for Page Content */}
        <ReactQuill
          value={pageData.content}
          onChange={handleContentChange}
          placeholder="Page Content"
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline"],
              ["link", "image", "video"], // Added "video" for iframes
              [{ align: [] }], // Align text or images
              ["clean"], // Clear formatting button
              ["iframe"], // Allow embedding iframes (YouTube, etc.)
              ["imageResize"], // Allow resizing images
            ],
          }}
          formats={[
            "header",
            "font",
            "list",
            "bullet",
            "bold",
            "italic",
            "underline",
            "link",
            "image",
            "video", // Added to allow embedding videos or iframes
            "align", // For alignment support
          ]}
        />
        <button onClick={handleUpdatePage}>Update Page</button>
      </div>
    </div>
  );
};

export default EditPage;
